import { Link } from "gatsby"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React, {useState} from "react"
import Overlay from "./Overlay"

const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: key => ({
    opacity: 1,
    transition: {
      delay: 0.25 + key * 0.1,
      type: "tween",
    },
    x: 0,
  }),
}



const MenuMobile = ({ links, isOpen, setIsOpen }) => {

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const submenuVisible = React.useRef();
  submenuVisible.current = isSubmenuOpen;

  const SubMenuLink =({link, index, setIsOpen, isOpen}) => (
    <motion.li
      className="my-3"
      animate={isOpen ? "open" : "closed"}
      custom={index}
      variants={menuItem}
    >
      <Link
        className="font-semibold text-4xl text-white"
        activeClassName="text-blue-bright"
        onClick={() => {setIsSubmenuOpen(!isSubmenuOpen)}}
        to={"#"}
      >
        {link.name}
      </Link>
      <div className={submenuVisible.current ? "flex flex-col" : "hidden"}>
        {link.subMenu.map((item, key) => (
            <Link
              className="font-semibold text-2xl text-white"
              activeClassName="text-blue-bright"
              to={item.to}
              onClick={() => setIsOpen(false)}
              key={`{mobile_submenu_item_${key}`}
            >
            {item.name}
            </Link>
        ))}
      </div>
    </motion.li>
  )


  const NormalLink =({link, index, setIsOpen, isOpen, menuItem}) => (
    <motion.li
      className="my-3"
      animate={isOpen ? "open" : "closed"}
      custom={index}
      variants={menuItem}
    >
      <Link
        className="font-semibold text-4xl text-white"
        activeClassName="text-blue-bright"
        to={link.to}
        onClick={() => setIsOpen(false)}
      >
        {link.name}
      </Link>
    </motion.li>
  )

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-center">
        <ul className="text-center">
        {links.map((link, index) => (
          link.subMenu[0] ? <SubMenuLink link={link} key={ `mobile_menu_item_${link.name}`} index={index} setIsOpen={setIsOpen} isOpen={isOpen} menuItem={menuItem} /> : <NormalLink link={link} key={ `mobile_menu_item_${link.name}`} index={index} setIsOpen={setIsOpen} isOpen={isOpen} menuItem={menuItem}/>
        ))}
        </ul>
      </div>
    </Overlay>
  )
}

MenuMobile.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default MenuMobile
