import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa"

const Footer = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            facebook
            linkedin
            twitter
          }
        }
      }
    }
  `)

  return (
    <div className="w-screen px-8 lg:px-24 py-12 md:flex md:items-start md:justify-between">

      <div className="mt-8 md:mt-0 md:order-1">
        <h4 className="text-blue-bright py-3">Contact</h4>
        <p className="text-sm py-1"><strong className="pr-3">Address:</strong>ZURLY LLC., ATLANTA, GA</p>
        <p className="text-sm py-1"><strong className="pr-3">Phone:</strong>+1 (888) 456-8410</p>
        <p className="text-sm py-1"><strong className="pr-3">Email:</strong>INFO@ZURLY.COM</p>
      </div>

      <div className="mt-8 md:mt-0 md:order-2">
        <h4 className="text-blue-bright py-3 md:text-right">Zurly</h4>
        <ul className="flex justify-start md:justify-end py-2">
          <FooterLink href={links.twitter} icon={FaTwitter} label="Twitter" />
          <FooterLink href={links.facebook} icon={FaFacebook} label="Facebook" />
          <FooterLink href={links.linkedin} icon={FaLinkedin} label="Linkedin" />

        </ul>
        <p className="pt-2 text-sm md:text-right">
          &copy; 2020 Zurly
        </p>
      </div>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block pr-6 md:pr-0 md:pl-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-500 hover:text-blue-600 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-5 h-5 fill-current" />
      </a>
    </li>
  )
}

export default Footer
