import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"
import LogoBlue from "../images/logoblue.png"
import Logo from "../images/logo.jpg"


const Header = () => {
  const useStyles = {
    appBarTransparent: "transition duration-500 ease-in",
    appBarSolid: "bg-herobg shadow-sm md:bg-opacity-100 transition duration-500 ease-in",
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [navBackground, setNavBackground] = useState('appBarTransparent')
  const [logoColor, setLogoColor] = useState(Logo)

  const [dropdown, setDropdown] = useState(false)

  const navRef = React.useRef()
  navRef.current = navBackground
  const logoRef = React.useRef()
  logoRef.current = logoColor

  const SubHeader = ({link, dropdown}) => {
    return (
      <div className={dropdown ? `pt-3 px-6 absolute transform translate-y-3 shadow-sm flex flex-col justify-center rounded-md bg-white bg-opacity-75 align-middle ${useStyles[navRef.current]}` : "opacity-0 hidden"}>
        {link.subMenu.map ((link, index) => {
          return(
            <Link
              key={`menu_desktop_sublink${index}`}
              className="mx-4 text-base font-medium px-px border-b-2 mb-3 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
              activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
              to={link.to}
            >
              {link.name}
            </Link>
          )
        })}
      </div>
    )
  }

  const Links = ({link, index}) => {
    if(link.name === "Solutions"){
      return(
        <div role="button" onMouseOver={()=>setDropdown(true)} onFocus={()=>setDropdown(true)} onMouseLeave={()=> setDropdown(false)} className="cursor-pointer inline-block ml-6 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out">
          {link.name}
         <SubHeader link={link} dropdown={dropdown} /> 
        </div>
      )
    } else {
      return (
        <Link
          key={`menu_desktop_link_${index}`}
          className="ml-6 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
          activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
          to={link.to}
        >
          {link.name}
        </Link>
      )
    }
  }

  useEffect(()=> {
    const handleScroll = () => {
      const show = window.scrollY > 200
      if (show) {
        setNavBackground('appBarSolid')
        setLogoColor(LogoBlue)

      } else {
        setNavBackground('appBarTransparent')
        setLogoColor(Logo)

      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll',handleScroll)
    }
  },[])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
            subMenu {
              name
              to
            }
          }
        }
      }
    }
  `)

  const btnStyle =
    "inline-block px-4 py-2 mx-6 font-medium leading-snug border border-transparent text-base rounded-md text-blue-bright bg-white border-blue-bright hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out z-30"
  return (
      <div className={`w-screen lg:px-20 pt-6 pb-6 md:pt-6 md:pb-6 fixed z-50 ${useStyles[navRef.current]}`}>
        <div className="flex justify-between items-center px-8 md:px-4 relative">
          <Link to="/">
            <img alt="Logo" className="w-20 md:w-24" src={logoRef.current} />
          </Link>
          <div className="flex md:hidden">
            <a href="https://app.realperks.com">
              <button className={`md:hidden ${btnStyle}`}>Login</button>
            </a>
            <button
              className="md:hidden m-0"
              onClick={() => setIsMenuOpen(true)}
              aria-label="Open Menu"
            >
              <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
            </button>
          </div>

          <div className="hidden md:block">
              {site.data.menu.map((link, index) => (
                <Links link={link} index={index} key={index}/>
              ))}
              <a href="https://app.realperks.com">
                <button className={btnStyle}>Login</button>
              </a>
          </div>
        </div>
        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          links={site.data.menu}
        />
      </div>
  )
}

export default Header
